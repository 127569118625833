import Form from '@searchtrends/webform-processor';

require('leaflet');

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/; SameSite=Strict";
}

/*if(document.querySelector('#map')) {
    let map = document.querySelector('#map').parentElement;

    if (map) {
        (function (map) {
            let searchform = document.querySelector('#search-form');

            if (! searchform) {
                return;
            }

            let observer = new IntersectionObserver(entries => {
                if (screen.width < 768) {
                    map.style.position = 'relative';
                    map.style.top = null;
                    map.style.width = null;

                    return;
                }

                if (entries[0].isIntersecting) {
                    map.style.position = 'relative';
                    map.style.top = null;
                    map.style.width = null;
                } else {
                    map.classList.add('pt-6');
                    map.style.position = 'fixed';
                    map.style.top = '0px';
                    map.style.width = `${map.parentElement.clientWidth}px`;
                }
            }, {
                threshold: .25
            });

            observer.observe(searchform);
        })(map);
    }
}*/

window.loadMapsJs = function () {
    initMap();
}



let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

if (document.querySelector('.mobile-menu')) {
    document.querySelector('.mobile-menu .closed').addEventListener('click', function (e) {
        document.querySelector('.mobile-menu .closed').classList.add('hidden');
        document.querySelector('.mobile-menu .open').classList.remove('hidden');
        document.querySelector('header nav .menu').style.display = 'flex';
    });
    document.querySelector('.mobile-menu .open').addEventListener('click', function (e) {
        document.querySelector('.mobile-menu .open').classList.add('hidden');
        document.querySelector('.mobile-menu .closed').classList.remove('hidden');
        document.querySelector('header nav .menu').style.display = 'none';
    });
}
